import React from "react";




const AML = () => {

    return (
         <div className="site-content">
        <section className="section-img">
            <div className="container">
			  <div className="row">
    <div className="col-md-7 extra-large">
	   <div className=" headabout">
	 <h1 className="about-heading">Anti-Money Laundering and Counter Terrorism (AML/CTF) Policy</h1>
	</div>
              
                <div>
                    <div className="">
                       <p className="our_vission01">Smartitude Solutions Pty Ltd (“Smartitude”) is a designated service provider under
                            Australian AML/CTF Act (“Act”). This Act of parliament passed in 2006 requires all
                            designated service providers to comply with subsisting AML/CTF regulatory requirements as
                            governed by AUSTRAC Australian Transaction Reports and Analysis Centre).
                        </p>
                       <p className="our_vission01">
                            Consequently, Smartitude is committed to fulfilling its regulatory requirements under the
                            Act. These include allocating adequate resources, including systems and procedures to
                            identify, mitigate and manage AML/CTF risks in the marketplace. We have developed a
                            rigorous AML/CTF Program based on the standards prescribed by the International
                            Compliance Association (ICA) and aligned to the expectations of AUSTRAC.
                        </p>
                        <p className="our_vission01">
                            Our uncompromising in our commitment to protecting the interest of our customers,
                            employees, partners and the community at large. Specific measures in our AML/CTF
                            Program include:
                            <ul>
                                <li>ID Verification and KYC approach</li>
                                <li>Money Laundering and Terrorism Funding (ML/TF) Risk Management</li>
                                <li>Rigorous Due Diligence Approach</li>
                                <li>Ongoing Training Program</li>
                                <li>Periodic Independent Review of our AML/CTF Program</li>
                                <li>Systems Capabilities to Meet with AUSTRAC Reporting Obligations</li>
                                <li>Record Keeping</li>
                            </ul>
                        </p>
                      <p className="our_vission01">
                            Smartitude will not transact with customers who fail to prove their identity. Our business
                            model has been specifically designed to reduce our exposure to ML/TF risk by limiting the
                            volume and value of transaction(s) a customer can initiate in a given time period.
                        </p>
                        <p className="our_vission01">Our digital business model lends itself to verification and audit. We do not offer cash-based
                            transaction either at point of origination or termination. We take money laundering and
                            terrorism financing seriously and are bound to report all cases to regulatory authorities.
                            Attempts to use our services to launder funds or finance terrorism exposes you to potential
                            criminal prosecution.</p>
                    </div>
                </div>
            </div>
			</div>
			</div>
        </section>
		</div>
    )
}

export default AML; 