const Bank_list = [
    {
        label: "Access Bank",
        value: "Access Bank"
    },
    {
        label: "Accion Microfinance Bank",
        value: "Accion Microfinance Bank"
    },
    {
        label: "Advans La Fayette Microfinance Bank",
        value: "Advans La Fayette Microfinance Bank"
    },
    {
        label: "Citibank Nigeria",
        value: "Citibank Nigeria"
    },
    {
        label: "Coronation Merchant Bank",
        value: "Coronation Merchant Bank"
    },
    {
        label: "Covenant Microfinance Bank",
        value: "Covenant Microfinance Bank"
    },
    {
        label: "Dot Microfinance Bank",
        value: "Dot Microfinance Bank"
    },
    {
        label: "Ecobank Nigeria",
        value: "Ecobank Nigeria"
    },
    {
        label: "Empire Trust Microfinance Bank",
        value: "Empire Trust Microfinance Bank"
    },
    {
        label: "FairMoney Microfinance Bank",
        value: "FairMoney Microfinance Bank"
    },
    {
        label: "FBNQuest Merchant Bank",
        value: "FBNQuest Merchant Bank"
    },
    {
        label: "Fidelity Bank",
        value: "Fidelity Bank"
    },
    {
        label: "Fina Trust Microfinance Bank",
        value: "Fina Trust Microfinance Bank"
    },
    {
        label: "Finca Microfinance Bank ",
        value: "Finca Microfinance Bank "
    },
    {
        label: "First Bank of Nigeria ",
        value: "First Bank of Nigeria "
    },
    {
        label: "First City Monument Bank",
        value: "First City Monument Bank"
    },
    {
        label: "FSDH Merchant Bank",
        value: "FSDH Merchant Bank"
    },
    {
        label: "Globus Bank",
        value: "Globus Bank"
    },
    {
        label: "Guaranty Trust Bank",
        value: "Guaranty Trust Bank"
    },
    {
        label: "Heritage Bank",
        value: "Heritage Bank"
    },
    {
        label: "Infinity Microfinance Bank",
        value: "Infinity Microfinance Bank"
    },
    {
        label: "Jaiz Bank",
        value: "Jaiz Bank"
    },
    {
        label: "Keystone Bank",
        value: "Keystone Bank"
    },
    {
        label: "Kuda Bank",
        value: "Kuda Bank"
    },
    {
        label: "LOTUS BANK",
        value: "LOTUS BANK"
    },
    {
        label: "Mint Finex MFB",
        value: "Mint Finex MFB"
    },
    {
        label: "Mkobo MFB",
        value: "Mkobo MFB"
    },
    {
        label: "Moniepoint Microfinance Bank",
        value: "Moniepoint Microfinance Bank"
    },
    {
        label: "Mutual Trust Microfinance Bank",
        value: "Mutual Trust Microfinance Bank"
    },
    {
        label: "Nova Merchant Bank",
        value: "Nova Merchant Bank"
    },
    {
        label: "Opay",
        value: "Opay"
    },
    {
        label: "Optimus Bank",
        value: "Optimus Bank"
    },
    {
        label: "Palmpay",
        value: "Palmpay"
    },
    {
        label: "Parallex Bank",
        value: "Parallex Bank"
    },
    {
        label: "Peace Microfinance Bank",
        value: "Peace Microfinance Bank"
    },
    {
        label: "Pearl Microfinance Bank",
        value: "Pearl Microfinance Bank"
    },
    {
        label: "Polaris Bank",
        value: "Polaris Bank"
    },
    {
        label: "PremiumTrust Bank",
        value: "PremiumTrust Bank"
    },
    {
        label: "Providus Bank",
        value: "Providus Bank"
    },
    {
        label: "Rand Merchant Bank",
        value: "Rand Merchant Bank"
    },
    {
        label: "Raven Bank",
        value: "Raven Bank"
    },
    {
        label: "Rephidim Microfinance Bank",
        value: "Rephidim Microfinance Bank"
    },
    {
        label: "Rex Microfinance Bank",
        value: "Rex Microfinance Bank"
    },
    {
        label: "Rubies Bank",
        value: "Rubies Bank"
    },
    {
        label: "Shepherd Trust Microfinance Bank",
        value: "Shepherd Trust Microfinance Bank"
    },
    {
        label: "Sparkle Bank",
        value: "Sparkle Bank"
    },
    {
        label: "Stanbic IBTC Bank",
        value: "Stanbic IBTC Bank"
    },
    {
        label: "Standard Chartered Bank",
        value: "Standard Chartered Bank"
    },
    {
        label: "Sterling Bank",
        value: "Sterling Bank"
    },
    {
        label: "SunTrust Bank",
        value: "SunTrust Bank"
    },
    {
        label: "TAJBank",
        value: "TAJBank"
    },
    {
        label: "Titan Trust Bank",
        value: "Titan Trust Bank"
    },
    {
        label: "Titan Trust Bank",
        value: "Titan Trust Bank"
    },
    {
        label: "Union Bank of Nigeria",
        value: "Union Bank of Nigeria"
    },
    {
        label: "United Bank For Africa",
        value: "United Bank For Africa"
    },
    {
        label: "Unity Bank",
        value: "Unity Bank"
    },
    {
        label: "VFD Microfinance Bank",
        value: "VFD Microfinance Bank"
    },
    {
        label: "Wema Bank",
        value: "Wema Bank"
    },
    {
        label: "Zenith Bank",
        value: "Zenith Bank"
    },
    {
        label: "other",
        value: "other"
    }
]

export default Bank_list